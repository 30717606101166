<template>
    <section>
        <div class="row mx-0 justify-center">
            <div class="col-8 text-center font-omnes f-30 mb-5 text-general-red">
                GANASTE
            </div>
            <div class="position-relative">
                <div class="like-square">
                    <div class="sc-white" />
                </div>            
                <!-- Image Reward -->
                <img :src="imgReward(rewardType, urlReward)" width="170" height="170" :class="`obj-cover position-image-rw${rewardType == 4 ? '-pd' : ''} br-12`" />
                <lottie-animation
                v-if="rewardType == 2 || rewardType == 3 || rewardType == 4"
                ref="lottie"
                class="animation-confetti"
                :loop="true"
                :auto-play="true"
                :width="400"
                :height="407"
                path="animations/confetti.json"
                />
            </div>
            <div v-if="rewardType == 1" class="col-8 text-general-red mt-5 mb-4 font-omnes f-31 text-center">
                SIGUE INTENTANDO
            </div>
            <div v-if="rewardType == 2" class="col-8 d-middle-center mt-5 mb-4">
                <div class="space-coupon d-middle text-white font-omnes f-20">
                    <img src="/img/gaming/i_cupon.svg" class="position-absolute" width="80" height="80" />
                    Cupon: $ 50.000
                </div>
            </div>
            <div v-if="rewardType == 4" class="col-8 d-middle-center mt-5 mb-4">
                <div class="space-coupon d-middle text-white font-omnes f-20">
                    Canguro nike
                </div>
            </div>
            <div v-if="rewardType == 3" class="col-8 d-middle-center mt-5 mb-4">
                <div class="space-coin-gem d-middle text-white font-omnes mr-3 f-20">
                    <img src="/img/gaming/i_moneda.svg" class="position-absolute" width="50" height="50" />
                    + 45
                </div>
                <div class="space-coin-gem d-middle text-white font-omnes ml-3 f-20">
                    <img src="/img/gaming/i_gema.svg" class="position-absolute" width="50" height="50" />
                    + 1
                </div>
            </div>
            <div class="col-8 btn-gr-purple font-omnes text-white justify-center cr-pointer" @click="continuar()">
                Continuar
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        rewardType:{
            type: Number,
            default: 1
        },
        urlReward: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            imgReward(type, urlReward){
                switch (type){
                case 1:
                    return '/img/gaming/i_triste.png';
                case 2:
                    return '/img/gaming/i_cupon.png';
                case 3:
                    return '/img/gaming/i_group_regalo.png';
                case 4:
                    return urlReward;
                }
            }
        }
    },
    methods: {
        continuar(){
            console.log('continuar')
        }
    }
}
</script>

<style lang="scss" scoped>
.position-image-rw{
    position: absolute;
    right: 8%;
    top: 25%;
    z-index: 2;
    &-pd{
        @extend .position-image-rw;
        top: 9% !important;
    }
}
.like-square{
    width: 200px;
    height: 200px;
    position: relative;
    padding: 25px;
    z-index: 1;
    border-radius: 20px;
    background: radial-gradient(circle at 52% 111%, #612AD5, #9F4BFF, #F9C7FF);
    transform: rotate(45deg);
    box-shadow: 0px 10px 20px #9A48FB80;
    .sc-white{
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        opacity: .2;
        border-radius: 20px;
    }
}

.animation-confetti{
    position: absolute;
    z-index: 0;
    top: -195px;
    left: -102px;
}

.btn-gr-purple{
    height: 44px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    position: relative;
    background-image: linear-gradient(to bottom, #F9C7FF, #9F4BFF, #612AD5);
}

.space-coupon{
    min-width: 214px;
    height: 40px;
    position: relative;
    border-radius: 8px;
    padding-left: 53px;
    background-image: linear-gradient(to right, rgb(64, 33, 128), rgb(134, 91, 228));
    img{
        left: -42px;
    }
}

.space-coin-gem{
    width: 106px;
    height: 40px;
    position: relative;
    border-radius: 8px;
    padding-left: 44px;
    background-image: linear-gradient(to right, rgb(64, 33, 128), rgb(134, 91, 228));
    img{
        left: -19px;
    }
}
</style>